import * as R from 'ramda'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { faFile } from '@fortawesome/free-regular-svg-icons'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  Input,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Link,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { PictureAsPdf } from '@material-ui/icons'
import { DropzoneArea } from 'material-ui-dropzone'
import Swal from 'sweetalert2'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'

import { mapIndexed, nilOrEmpty, notNilOrEmpty } from '../lib/Helpers'
import Exhibitor from '../services/Exhibitor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExhibitorsDropdown from './ExhibitorsDropdown'

const INITIAL_EXHIBITOR_STATE = {
  title: '',
  company_address: '',
  company_phone: '',
  company_primary_contact: '',
  company_primary_contact_email: '',
  company_url: '',
  category: '',
  company_img: null,
  company_img_name: '',
  company_file_asset: null,
  company_zoom_url: '',
  company_info: '',
  company_video_url: '',
  challengeType: '',
  submission_details_url: '',
  solution_id: '',

  bc__company_who_are_you_img: null,
  bc__company_who_are_you_img_name: null,
  bc__who_are_you: ''
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  divider: {
    margin: '0 1rem'
  },
  companyImage: {
    width: '100px',
    height: '100px',
    margin: 'auto',
    marginBottom: '1rem'
  },
  exhibitorImageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column'
  },
  filesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    flexWrap: 'wrap'
  },
  fileContainer: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    padding: '0.5rem'
  },
  fileIcon: {
    margin: 'auto',
    marginBottom: '1rem'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgba(255,255,255,0.7)',
    zIndex: 1000,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  loader: {
    margin: 'auto'
  }
}))

const findIndex = (arr, val) => R.findIndex(R.propEq('_id', val))(arr)

function getSteps() {
  return [
    'Account Type',
    'Booth Creation',
    'Booth Content',
    'Company Information'
  ]
}

const getFormInitialState = exhibitor => {
  if (exhibitor === 'new' || !exhibitor) {
    return INITIAL_EXHIBITOR_STATE
  }
  return {
    ...exhibitor,
    category: exhibitor.categories._ref,
    challengeType: exhibitor.challengeType._ref
  }
}

export default ({
  client,
  session,
  onSignOut,
  setUserExhibitor,
  exhibitor,
  exhibitors,
  fetchExhibitors
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoadingState] = React.useState(false)
  const [cats, setCats] = React.useState([])
  const [challengeType, setChallengeType] = React.useState({})
  const [availableSubCats, setAvailableSubcats] = React.useState(false)
  const [formData, setFormData] = React.useState(getFormInitialState(exhibitor))
  const [activeStep, setActiveStep] = React.useState(0)
  const [selectedChallengeType, setSelectedChallengeType] = React.useState('')
  const steps = getSteps()

  React.useEffect(() => {
    getClient()
  }, [])

  React.useEffect(() => {
    const shouldDefineCategoriesFromVendor =
      formData._id != null &&
      formData.challengeType != null &&
      challengeType.length > 0
    if (shouldDefineCategoriesFromVendor) {
      const catIndex = findIndex(challengeType, formData.challengeType)
      if (catIndex in challengeType && challengeType[catIndex].categories) {
        setSelectedChallengeType(challengeType[catIndex].title)
        setCats(challengeType[catIndex].categories)
      }
    }
  }, [formData._id, challengeType, formData._rev])

  React.useEffect(() => {
    const isDifferentRevision =
      '_rev' in formData &&
      exhibitor != null &&
      exhibitor._rev !== formData._rev
    const isNewExhibitor =
      !('_id' in formData) && exhibitor != null && exhibitor._id != null
    if (isDifferentRevision || isNewExhibitor) {
      setFormData(getFormInitialState(exhibitor))
    }
  }, [exhibitor])

  function getClient() {
    const query = `*[_type == "vendorType"] {
        _id,
        title,
        slug,
        categories[]->{...}
      }
    `
    const cats = `*[_type == "category"]`

    client
      .fetch(query)
      .then(vt => {
        setChallengeType(vt)
      })
      .catch(err => console.error(err))
  }

  function _handleInputChange(e) {
    e.preventDefault()
    if (e.target.name === 'solution_id' && e.target.value.length > 4) {
      return
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

    if (e.target.name === 'category')
      if (notNilOrEmpty(cats[findIndex(cats, e.target.value)].subcategories)) {
        setAvailableSubcats(true)
      } else {
        setAvailableSubcats(false)
      }
  }

  function _handleAddingImage(id, e) {
    const name = `${id}_name`
    notNilOrEmpty(e) &&
      setFormData({
        ...formData,
        [id]: e[0],
        [name]: e[0].name
      })
  }

  function _handleAddingFiles(e) {
    notNilOrEmpty(e) &&
      setFormData({
        ...formData,
        company_file_asset: e
      })
  }

  function _handleChallengeType(e) {
    e.preventDefault()

    setAvailableSubcats(false)

    const catIndex = findIndex(challengeType, e.target.value)

    setFormData({
      ...formData,
      category: '',
      [e.target.name]: e.target.value
    })

    if ([catIndex].categories) {
      setSelectedChallengeType([catIndex].title)
      setAvailableSubcats(true)
    } else {
      setAvailableSubcats(false)
    }

    setCats(challengeType[catIndex].categories)
  }

  async function _handleForm(e) {
    try {
      e.preventDefault()
      setIsLoadingState(true)
      // creating the object to send to sanity
      const nextExhibitor = new Exhibitor(
        formData,
        client,
        session.user._id,
        getFormInitialState(exhibitor)
      )
      const exhibitorRecord = await (nextExhibitor.isNew()
        ? nextExhibitor.createExhibitor()
        : nextExhibitor.updateExhibitor())

      Swal.fire({
        title: 'Thank You',
        text: 'We received your information.',
        icon: 'success',
        showConfirmButton: false,
        confirmButtonText: 'Send',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setUserExhibitor(exhibitorRecord)
      setActiveStep(0)
      setIsLoadingState(false)
      fetchExhibitors()
    } catch (error) {
      console.error('ERROR', error)
      setIsLoadingState(false)
      Swal.fire({
        title: 'Failed to save your information.',
        text: 'Please try again or contact support!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
    }
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const removeExhibitorResource = (unset, resourceType) => {
    setIsLoadingState(true)
    client
      .patch(formData._id)
      .unset(unset)
      .commit()
      .then(async res => {
        const exhibitorRecord = new Exhibitor({}, client, null, {})
        const updatedExhibitor = await exhibitorRecord.getLatestDocument(
          formData._id
        )
        setUserExhibitor(updatedExhibitor)
        setIsLoadingState(false)
        Swal.fire({
          title: 'Record Deleted',
          text: `The requested ${resourceType} was deleted successfuly.`,
          icon: 'success',
          showConfirmButton: false,
          confirmButtonText: 'Send',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
      .catch(err => {
        console.error('Delete failed: ', err.message)
        setIsLoadingState(false)
        Swal.fire({
          title: `Failed to delete the requested ${resourceType}.`,
          text: 'Please try again or contact support!',
          icon: 'error',
          showConfirmButton: false,
          confirmButtonText: '',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
  }

  function _createNewExhibitor() {
    setUserExhibitor('new')
    setActiveStep(0)
    setFormData(getFormInitialState('new'))
  }

  return (
    <div className="container exhibitor-page page--new-exhibitor">
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
      <div className={`animated fadeIn section-content`}>
        {notNilOrEmpty(session) && notNilOrEmpty(session.user) ? (
          <Box
            display="flex"
            p={1}
            flexDirection="row"
            alignItems="center"
            justifyItems="space-between"
          >
            <Typography variant="caption">
              Signed In As: {session.user.email}
            </Typography>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Typography variant="caption">
              <Link href="#" onClick={onSignOut}>
                Sign Out
              </Link>
            </Typography>
            <Button
              onClick={_createNewExhibitor}
              style={{ marginLeft: 'auto', marginRight: '1rem' }}
              className="btn--yellow"
              variant="contained"
              color="primary"
            >
              Add New
            </Button>
            {notNilOrEmpty(session.user) && notNilOrEmpty(exhibitors) && (
              <ExhibitorsDropdown
                exhibitors={exhibitors}
                exhibitor={exhibitor}
                setSelectedExhibitor={setUserExhibitor}
              />
            )}
          </Box>
        ) : null}
        <div className="form-container">
          <form onSubmit={_handleForm}>
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step>
                <StepLabel>{steps[0]}</StepLabel>
                <StepContent>
                  <FormControl variant="outlined" className="form-input">
                    <InputLabel id="vendor-label">Challenge*</InputLabel>
                    <Select
                      label="Challenge"
                      labelId="challenge-label"
                      id="cats-select"
                      name="challengeType"
                      onChange={_handleChallengeType}
                      value={formData.challengeType}
                      required
                    >
                      {mapIndexed((type, index) => {
                        return (
                          <MenuItem key={index} value={type._id}>
                            <ListItemText primary={R.toUpper(type.title)} />
                          </MenuItem>
                        )
                      })(
                        R.compose(R.sort(R.ascend(R.prop('title'))))(
                          challengeType
                        )
                      )}
                    </Select>
                  </FormControl>
                  {notNilOrEmpty(cats) && (
                    <FormControl variant="outlined" className="form-input">
                      <InputLabel id="cats-label">Category *</InputLabel>
                      <Select
                        label="Categories"
                        labelId="cats-label"
                        id="cats-select"
                        name="category"
                        onChange={_handleInputChange}
                        value={formData.category}
                        required
                      >
                        {mapIndexed((cat, index) => {
                          return (
                            <MenuItem key={index} value={cat._id}>
                              <ListItemText primary={R.toUpper(cat.title)} />
                            </MenuItem>
                          )
                        })(R.compose(R.sort(R.ascend(R.prop('title'))))(cats))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      error={
                        formData.solution_id.length > 0
                          ? formData.solution_id.length !== 4
                          : false
                      }
                      helperText={
                        formData.solution_id.length > 0
                          ? 'Your solution identifier must be 4 digits'
                          : 'Enter your solution identifier'
                      }
                      label="Your Fusion Solution ID"
                      variant="outlined"
                      color="secondary"
                      name="solution_id"
                      onChange={_handleInputChange}
                      value={formData.solution_id}
                      type="number"
                      required
                      inputProps={{
                        maxlength: 4,
                        minlength: 4
                      }}
                    />
                  </FormControl>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={R.not(
                          R.and(
                            notNilOrEmpty(formData.challengeType),
                            R.equals(4, R.length(formData.solution_id))
                          )
                        )}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>{steps[1]}</StepLabel>
                <StepContent>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      helperText={`Please briefly tell us about your solution (750 char limit) / ${750 -
                        R.length(formData.bc__who_are_you)} chars left`}
                      label="Your Solution"
                      variant="outlined"
                      color="secondary"
                      name="bc__who_are_you"
                      onChange={_handleInputChange}
                      value={formData.bc__who_are_you}
                      multiline
                      rows={4}
                      required
                      error={R.gte(R.length(formData.bc__who_are_you), 750)}
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      helperText={`Enter your Solution URL from The Fusion Challenge website`}
                      label="Your Fusion Challenge Solution URL"
                      variant="outlined"
                      color="secondary"
                      name="submission_details_url"
                      onChange={_handleInputChange}
                      value={formData.submission_details_url}
                    />
                  </FormControl>
                  <InputLabel className="form-input" id="logo-label">
                    Who Are You Image (Team Picture)
                  </InputLabel>
                  {notNilOrEmpty(formData._id) &&
                    notNilOrEmpty(formData.bc__company_who_are_you_img) && (
                      <div className={classes.exhibitorImageContainer}>
                        <img
                          src={formData.bc__company_who_are_you_img_url}
                          alt="Company Image"
                          className={classes.companyImage}
                        />
                        <Button
                          color="secondary"
                          onClick={() =>
                            removeExhibitorResource([
                              'bc__company_who_are_you_img'
                            ])
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    )}
                  <FormControl variant="outlined" className="form-input">
                    <DropzoneArea
                      acceptedFiles={['image/*']}
                      dropzoneText={
                        'Drag and drop an image of your team here or click to upload'
                      }
                      filesLimit={1}
                      id="image-path"
                      name="bc__company_who_are_you_img"
                      onChange={e =>
                        _handleAddingImage('bc__company_who_are_you_img', e)
                      }
                      value={formData.bc__company_who_are_you_img}
                    />
                  </FormControl>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={R.not(
                          R.and(
                            notNilOrEmpty(formData.bc__who_are_you),
                            R.lte(R.length(formData.bc__who_are_you), 750)
                          )
                        )}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>{steps[2]}</StepLabel>
                <StepContent>
                  <InputLabel required className="form-input" id="logo-label">
                    Team Logo
                  </InputLabel>
                  {formData._id != null && formData.company_img_url != null && (
                    <div className={classes.exhibitorImageContainer}>
                      <img
                        src={formData.company_img_url}
                        alt="Company Image"
                        className={classes.companyImage}
                      />
                      <Button
                        color="secondary"
                        onClick={() => removeExhibitorResource(['company_img'])}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                  <FormControl variant="outlined" className="form-input">
                    <DropzoneArea
                      required
                      acceptedFiles={['image/*']}
                      dropzoneText={
                        'Drag and drop your team logo here or click'
                      }
                      filesLimit={1}
                      id="image-path"
                      name="company_img"
                      onChange={e => _handleAddingImage('company_img', e)}
                      value={formData.company_img}
                    />
                  </FormControl>
                  <InputLabel className="form-input" id="logo-label">
                    Team Brochure(s)
                  </InputLabel>
                  {formData._id != null && (
                    <div className={classes.filesContainer}>
                      {notNilOrEmpty(formData.company_file_asset) &&
                        formData.company_file_asset.map(asset =>
                          asset._key != null ? (
                            <div className={classes.fileContainer}>
                              <FontAwesomeIcon
                                icon={faFile}
                                mask={['circle']}
                                size="2x"
                                className={classes.fileIcon}
                              />
                              <Button
                                color="secondary"
                                onClick={() =>
                                  removeExhibitorResource([
                                    `company_file_asset[_key=="${asset._key}"]`
                                  ])
                                }
                              >
                                Remove
                              </Button>
                            </div>
                          ) : null
                        )}
                    </div>
                  )}
                  <FormControl variant="outlined" className="form-input">
                    <DropzoneArea
                      acceptedFiles={['application/pdf']}
                      dropzoneText={
                        'Upload relevant materials: 10mgb per file, 3 files max'
                      }
                      id="file-path"
                      getPreviewIcon={() => <PictureAsPdf />}
                      maxFileSize={10000000}
                      name="company_file_asset"
                      onChange={_handleAddingFiles}
                      value={formData.company_file_asset}
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Team Video"
                      variant="outlined"
                      color="secondary"
                      name="company_video_url"
                      onChange={_handleInputChange}
                      value={formData.company_video_url}
                      error={
                        notNilOrEmpty(formData.company_video_url) &&
                        !R.startsWith('http', formData.company_video_url)
                      }
                      helperText="Youtube Link Preferred (eg. https://youtu.be/wH3xZen_nBY)"
                    />
                  </FormControl>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={R.not(notNilOrEmpty(formData.company_img))}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>{steps[3]}</StepLabel>
                <StepContent>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Company Name"
                      variant="outlined"
                      color="secondary"
                      name="title"
                      onChange={_handleInputChange}
                      value={formData.title}
                      required
                      error={
                        R.equals(exhibitor, 'new')
                          ? R.gt(R.length(formData.title), 79)
                          : null
                      }
                      helperText={
                        R.equals(exhibitor, 'new')
                          ? `(79 characters limit) / ${79 -
                              R.length(formData.title)} chars left}`
                          : ''
                      }
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      color="secondary"
                      helperText="Please list the headquaters of your company"
                      label="Company HQ Address"
                      name="company_address"
                      onChange={_handleInputChange}
                      required
                      value={formData.company_address}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      color="secondary"
                      error={
                        notNilOrEmpty(formData.company_url) &&
                        !R.startsWith('http', formData.company_url)
                      }
                      helperText="Please include http:// or https://"
                      label="Company Website"
                      name="company_url"
                      onChange={_handleInputChange}
                      value={formData.company_url}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Company Primary Contact"
                      variant="outlined"
                      color="secondary"
                      name="company_primary_contact"
                      onChange={_handleInputChange}
                      value={formData.company_primary_contact}
                      required
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Company Primary Contact Email"
                      variant="outlined"
                      color="secondary"
                      type="email"
                      name="company_primary_contact_email"
                      error={
                        notNilOrEmpty(formData.company_primary_contact_email) &&
                        R.not(
                          notNilOrEmpty(
                            R.match(
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              formData.company_primary_contact_email
                            )
                          )
                        )
                      }
                      onChange={_handleInputChange}
                      value={formData.company_primary_contact_email}
                      required
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Company Phone"
                      variant="outlined"
                      color="secondary"
                      name="company_phone"
                      onChange={_handleInputChange}
                      value={formData.company_phone}
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Recurring Zoom Meeting URL"
                      variant="outlined"
                      color="secondary"
                      name="company_zoom_url"
                      error={
                        notNilOrEmpty(formData.company_zoom_url) &&
                        !R.startsWith('http', formData.company_zoom_url)
                      }
                      onChange={_handleInputChange}
                      value={formData.company_zoom_url}
                      helperText="Please include http:// or https://"
                    />
                  </FormControl>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={_handleForm}
                        className={classes.button}
                        disabled={R.not(
                          R.and(
                            R.and(
                              R.and(
                                notNilOrEmpty(formData.title),
                                R.cond([
                                  [
                                    R.equals('new'),
                                    R.always(
                                      R.lte(R.length(formData.title), 79)
                                    )
                                  ],
                                  [R.is(Object), R.always(true)],
                                  [R.T, R.T]
                                ])(exhibitor)
                              ),
                              notNilOrEmpty(formData.company_address)
                            ),
                            R.and(
                              notNilOrEmpty(formData.company_primary_contact),
                              notNilOrEmpty(
                                formData.company_primary_contact_email
                              )
                            )
                          )
                        )}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            </Stepper>
          </form>
        </div>
      </div>
    </div>
  )
}
