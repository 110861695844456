import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { notNilOrEmpty } from '../lib/Helpers'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& button': {
      width: '100%'
    }
  },
  form: {
    margin: '0 auto',
    maxWidth: '20rem',
    '& .form-input': {
      width: '80%!important'
    }
  },
  input: {
    marginBottom: '1.5rem',
    width: '100%'
  }
}))

export default ({ onSubmit, isLoading, onCancel }) => {
  const classes = useStyles()
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
    passwordConfirm: ''
  })
  const [blurredFields, setBlurredField] = React.useState([])
  function _handleInputChange(e) {
    e.preventDefault()
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const passwordsMatch = formData.passwordConfirm === formData.password

  const shouldShowPasswordError =
    formData.passwordConfirm.length > 0 &&
    formData.password.length > 0 &&
    blurredFields.includes('password') &&
    blurredFields.includes('passwordConfirm') &&
    !passwordsMatch

  const isValidEmail =
    formData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/) !=
    null
  const shouldShowEmailError = formData.email.length > 0 && !isValidEmail

  return (
    <div className="container">
      <div className={`animated fadeIn section-content`}>
        <div className="form-container">
          <form className={classes.form} onSubmit={() => onSubmit(formData)}>
            <Box
              display="flex"
              p={1}
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h5" gutterBottom>
                Sign Up
              </Typography>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  label="Email"
                  variant="outlined"
                  color="secondary"
                  name="email"
                  onChange={_handleInputChange}
                  value={formData.email}
                  size="small"
                  classes={{
                    root: classes.input
                  }}
                  {...{
                    error: shouldShowEmailError,
                    helperText: shouldShowEmailError
                      ? `Please enter a valid email.`
                      : ''
                  }}
                />
              </FormControl>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  type="password"
                  label="Password"
                  variant="outlined"
                  color="secondary"
                  name="password"
                  onChange={_handleInputChange}
                  value={formData.password}
                  size="small"
                  classes={{
                    root: classes.input
                  }}
                  onBlur={() =>
                    !blurredFields.includes('password') &&
                    setBlurredField([...blurredFields, 'password'])
                  }
                />
              </FormControl>
              <FormControl variant="outlined" className="form-input">
                <TextField
                  type="password"
                  label="Confirm Password"
                  variant="outlined"
                  color="secondary"
                  name="passwordConfirm"
                  onChange={_handleInputChange}
                  value={formData.passwordConfirm}
                  size="small"
                  classes={{
                    root: classes.input
                  }}
                  onBlur={() =>
                    !blurredFields.includes('passwordConfirm') &&
                    setBlurredField([...blurredFields, 'passwordConfirm'])
                  }
                  {...{
                    error: shouldShowPasswordError,
                    helperText: shouldShowPasswordError
                      ? `The provided passwords don't match`
                      : ''
                  }}
                />
              </FormControl>
              <Box
                display="flex"
                p={1}
                flexDirection="row"
                alignItems="center"
                justifyItems="space-between"
                className={classes.actionsContainer}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                  className={classes.button}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => onSubmit(formData)}
                  className={classes.button}
                  disabled={
                    !(
                      notNilOrEmpty(formData.email) &&
                      notNilOrEmpty(formData.password) &&
                      notNilOrEmpty(formData.passwordConfirm) &&
                      formData.password === formData.passwordConfirm &&
                      isValidEmail
                    )
                  }
                >
                  {isLoading ? <CircularProgress color="secondary"/> : 'Submit'}
                </Button>
              </Box>
            </Box>
          </form>
        </div>
      </div>
    </div>
  )
}
